$primary-color: #2D2E83;
$secondary-color: #95C11F;
$off-white-color: #F7F7F7;
$white-color: #FFFFFF;
// $text-color: #6B7C93;
$text-color: #606f84;
$header-font: 'Raleway', sans-serif;

$breakpoints: (
    // Small screen / phone
    small: 576px,
    // Medium screen / tablet
    medium: 768px,
    // Large screen / desktop
    large: 992px,
    // Extra large screen / wide desktop
    extralarge: 1200px
);

:root {
    --primary: #2D2E83;
    --secondary: #95C11F;
}
