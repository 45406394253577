.btn {
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);

    &.btn-primary {
        color: $white-color;
        background-color: $secondary-color;
        border-color: $secondary-color;

        &.focus, &:focus {
            box-shadow: 0 0 0 0.2rem rgba(149,193,31,0.5);
        }
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            @extend .btn-primary;
        }
        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus {
            background-color: $secondary-color;
            border-color: $secondary-color;
            box-shadow: 0 0 0 0.2rem rgba(149,193,31,0.5);
        }
    }
}

.intro-title {
    text-align: center;
    font-family: $header-font;
    color: $primary-color;
    margin-bottom: 2.5rem;
}

.card {
    border: none;
    -webkit-box-shadow: 0px 2px 30px 0px rgba(14, 2, 34, 0.12);
    -moz-box-shadow: 0px 2px 30px 0px rgba(14, 2, 34, 0.12);
    box-shadow: 0px 2px 30px 0px rgba(14, 2, 34, 0.12);

    .card-thumb {
        display: inline-block;
        height: 200px;
        transition: 0.5s;
        &:hover {
            opacity: 0.7;
        }
        img {
            height: 100%;
            max-height: 200px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        @include respond-to(medium) {
            height: 155px;
            img {
                height: 100%;
                max-height: 155px;
            }
        }
        @include respond-to(large) {
            height: 250px;
            img {
                height: 100%;
                max-height: 250px;
            }
        }
    }

    .card-title {
        &.primary {
            color: $primary-color;
            min-height: calc(1.2 * 1.25rem * 3);
        }
        a {
            color: $primary-color;
        }
    }
    .card-text {
        font-size: 13px;
        line-height: 21px;
    }
}
.col-lg-4 .card .card-thumb {
    height: 250px;
    img {
        max-height: 250px;
    }
}

.svg-green {
    fill: $primary-color;
}

p, ul, li {
    font-size: 13px;
    color: $text-color;
}
#content {
    padding: 3rem 0;

    &.no-top-padding {
        padding-top: 0;
    }

    h1, h2, h3 {
        font-family: $header-font;
        color: $primary-color;
    }
    p, ul, li {
        font-size: 15px;
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1.6rem;
        list-style: inherit;

        &.lead {
            font-size: 18px;
            color: #697280;
            font-weight: 600;
        }
    }
}

.pagination {
    margin-top: 1rem;
    .page-item {
        &.active {
            .page-link {
                color: $white-color;
                background-color: $secondary-color;
                box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
                &:hover {
                    background-color: $secondary-color;
                }
            }
        }

        .page-link {
            transition: all .3s linear;
            outline: none;
            border: none;
            border-radius: 3px;
            padding: 0.5rem 1rem;
            background-color: transparent;
            color: $text-color;

            &:hover {
                background-color: #eee;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
}

.payoff {
    display: flex;
    position: relative;
    width: 100%; height: 400px;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0 3rem;

    &.small {
        height: 200px !important;
    }

    &.mid {
        height: 400px !important;
    }

    &.big {
        height: 600px !important;
    }

    h3 { z-index: 2; }

    &.filter {
        h3.text-center {
            color: $white-color !important;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0; top: 0; right: 0; bottom: 0;
            background-color: $primary-color;
            opacity: 0.5;
            z-index: 1;
        }
    }
}

ul {

    li {
        a {
            img.icon {
                display: inline;
                height: 16px;
                color: $secondary-color;
                margin-right: 10px;
            }
        }
    }
}

.list-group-item {
    color: $primary-color !important;
}

.list-group-item.active {
    color: white !important;
    background-color: $primary-color !important;
    border-color: $primary-color !important;
}