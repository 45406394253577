header {
    position: relative;
    overflow: hidden;
}

#stripes {
    position: absolute;
    width: 100%; height: 100%;
    overflow: hidden;
    transform: skewY(-10deg);
    transform-origin: 0;
    z-index: 1;

    span {
        position: absolute;
    }

    span:first-child {        
        width: 38%; height: 150px;
        left: 0;
        background: linear-gradient(180deg, #3E40B5 100%, #383AA6 100%);
    }
    span:nth-child(2) {
        width: 28%; height: 120px;
        top: 40px; right: 0;
        background: linear-gradient(222deg, #3E40B5 100%, #3E40B5 100%);
        opacity: 0.5;
    }    
    span:nth-child(3) {
        width: 40%; height: 100px;
        left: 0; bottom: 0;
        background: linear-gradient(90deg, #3E40B5 100%, #383AA6 100%);
        opacity: 0.5;
    }

    @include respond-to(small) {
        span:first-child {        
            width: 33%; height: 190px;
            left: -16%;
            background: linear-gradient(180deg, #3E40B5 100%, #383AA6 100%);
        }
        span:nth-child(2) {
            width: 33%; height: 190px;
            top: 190px; right: 0;
            background: linear-gradient(222deg, #3E40B5 100%, #3E40B5 100%);
            opacity: 0.5;
        }    
        span:nth-child(3) {
            width: 33%; height: 190px;
            left: 0; bottom: 0;
            background: linear-gradient(90deg, #3E40B5 100%, #383AA6 100%);
            opacity: 0.5;
        }
    }
}

.header-big {
    #intro {
        height: 600px;
        @include respond-to(small) {
            height: 760px;
        }
    }
}
.header-small {
    #intro {
        height: 300px;

        #curves {
            height: 8vh;
            @include respond-to(small) {
                height: 130px;
            }
        }
    }
}
.header-medium {
    #intro {
        height: 400px;
        .payoff {
            align-items: normal;
            padding-top: 140px;
        }
        #curves {
            max-height: 150px;
        }
    }
    #stripes {
        display: none;
    }
}

#intro {
    position: relative;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    height: 600px;
    background: linear-gradient(107deg, #2D2E83 100%, #3E40B5 100%);

    h1 {
        color: $white-color;
        font-size: 40px;
        margin-bottom: 20px;
    }
    p {
        color: $white-color;
    }
    .valena {
        width: 55%;
        height: auto;
    }

    #curves {
        position: absolute;
        width: 100%; height: auto;
        max-height: 200px;
        left: 0; bottom: 0;
        z-index: 2;

        .cls-1 { fill: #dcdcf0; }
        .cls-2 { fill: #ebebf6; }
        .cls-3 { fill: #fff; }
    }
    .header-text {
        z-index: 1;
    }
}

.navbar {
    position: absolute;
    left: 0; top: 10px; right: 0;
    z-index: 100;
    width: 100%; height: 75px;

    .navbar-brand img {
        width: 100px;

        @include respond-to(medium) {
            width: 140px;
        }
    }
    .nav-item {
        font-size: 16px;
        letter-spacing: 0.8px;
    }
}

.navbar-toggler-span {
    position: absolute;
    width: 32px; height: 3px;
    background-color: $white-color;
    right: 15px;
}
.navbar-toggler {
    cursor: pointer;
    position: relative;
    width: 50px; height: 50px;
    padding: 0 30px;

    &:focus {
        outline: none;
    }
    &:before {
        content: '';
        @extend .navbar-toggler-span;
        top: 12px;
    }
    &:after {
        content: '';
        @extend .navbar-toggler-span;
        bottom: 9px;
    }
}

.navbar-collapse {
    position: absolute;
    top: 0; left: 10px; right: 10px;
    background-color: $white-color;
    border-radius: 5px;

    .navbar-nav { 
        width: 100%;
    }

    .navbar-close {
        position: absolute;
        width: 50px; height: 50px;
        top: 0; right: 0;
        background-color: transparent;
        border: none;

        &:focus {
            outline: none;
        }
        &:before, &:after {
            content: '';
            position: absolute;
            left: 14px; top: 24px; right: 14px;
            height: 3px;
            background-color: $primary-color;
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }

    .nav-item {
        margin-top: 0.5rem;
        .nav-link {
            color: $text-color;
            margin: 0;
            padding: 1rem 1.5rem;

            &.btn-primary {
                color: $white-color;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    @include respond-to(small) {
        position: relative;
        background-color: transparent;
        
        .navbar-close {
            display: none;
        }

        .nav-item {
            margin-top: 0;

            .nav-link {
                margin-left: 0.5rem;
                padding: 0.5rem 0rem;
                color: $white-color;

                &.btn.btn-primary {
                    border-radius: 5px;
                    padding: 0.5rem 1rem;
                }
            }
        }
    }

    @include respond-to(large) {
        .nav-item .nav-link {
            padding: 0.5rem 1rem;
        }
    }
}
