.featured {
    padding-bottom: 3rem;

    .card-thumb {
        position: relative;
        display: inline-block;
        width: 100%;
        transition: 0.5s;
        &:hover {
            opacity: 0.7;            
        }
        img {
            border-radius: 5px;
        }
        .label {
            position: absolute;
            left: 0; top: 0;
            padding: 1rem 1.5rem;
            background-color: rgba($white-color, 0.5);
            color: $primary-color;
            border-bottom-right-radius: 5px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
        }
    }
    .card-title {
        &.primary {
            color: $primary-color;
        }
        a {
            color: $primary-color;
            transition: 0.3s;
        }
    }

    .card-text a {
        color: $secondary-color;
        font-size: 15px;
    }
}
