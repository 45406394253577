#content-valina {
    ul {
        li {
            line-height: 2.8rem;
            color: $primary-color;
            font-size: 1.2rem;
            &.selected {
                color: $secondary-color;
            } 
        }
    }

    h5 {
        color: $primary-color;
    }
}