@import '_variables';
@import '_mixins';
@import '_defaults';
@import '_header';
@import '_footer';
@import '_blog';
@import '_forms';
@import '_valina';
@import '_image';

.fonts-loaded [class^="icon-"]:before {
    font-family: "fontello";
}

.fonts-loaded [class*=" icon-"]:before {
    font-family: "fontello";
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-style: normal;
    font-weight: normal;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

#latest-items {
    background-color: $secondary-color;
    padding-top: 85px;
    padding-bottom: 85px;

    .intro-title {
        color: $white-color;
    }
}

.info-blocks {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .card {
        .card-body {
            padding: 45px;
        }
        .btn.btn-primary {
            padding: 0.5rem 3rem;
        }
    }
}

a.link-secondary {
    cursor: pointer !important;
    color: $secondary-color !important;
    text-decoration: underline !important;

    &:hover {
        color: $primary-color !important;
    }
}

.none {
    display: none;
}