form {
    .title {
        text-transform: uppercase;
        font-size: 30px;
        letter-spacing: 1.1px;
        margin-bottom: 20px;
        color: #2d2e83;
    }
    .group {
        label {
            text-transform: uppercase;
            font-size: 13px;
        }
        .wrap-input {
            position: relative;
            width: 100%;
            background-color: #FFF;
            border: 1px solid #E6E6E6;
            border-radius: 0.25rem;
            margin-bottom: 20px;

            input {
                display: block;
                width: 100%; height: 45px;
                padding: 0 15px;
                background: transparent;
                font-size: 14px;

                border: none;
                outline: none;

                &:focus + .focus-input {
                    visibility: visible;
                    opacity: 1;

                    transform: scale(1);
                    -webkit-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                    -o-transform: scale(1);
                }

                &:read-only {
                    background-color: #E6E6E6;
                    cursor: default;
                }

                &.error {
                    border: 1px solid red;
                    border-radius: 0.25rem;
                }
            }

            .focus-input {
                position: absolute;
                left: -1px; top: -1px;
                display: block;
                width: calc(100% + 2px); height: calc(100% + 2px);
                pointer-events: none;
                border: 1px solid #2d2e83;
                border-radius: 0.25rem;
                visibility: hidden;
                opacity: 0;

                -webkit-transition: all 0.4s;
                -o-transition: all 0.4s;
                -moz-transition: all 0.4s;
                transition: all 0.4s;

                -webkit-transform: scaleX(1.1) scaleY(1.3);
                -moz-transform: scaleX(1.1) scaleY(1.3);
                -ms-transform: scaleX(1.1) scaleY(1.3);
                -o-transform: scaleX(1.1) scaleY(1.3);
                transform: scaleX(1.1) scaleY(1.3);
            }
        }
    }
    .form-checkbox {
        input {
            display: none;
            &:checked + label:before {
                color: #95c11f;
            }
        }
        label {
            position: relative;
            display: block;
            font-size: 13px;
            line-height: 1.4;
            color: #999999;
            padding-left: 26px;
            cursor: pointer;

            &:before {
                content: '\e801';
                font-family: "fontello";
                font-size: 13px;
                color: transparent;

                display: flex;
                justify-content: center;
                align-items: center;

                position: absolute;
                width: 18px; height: 18px;
                left: 0; top: 50%;
                border-radius: 0.25rem;
                background-color: #FFF;
                border: 1px solid #E6E6E6;

                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
            }
        }
    }
    .form-forgot a {
        color: #555555;
        font-size: 13px;
    }
    .btn.btn-primary {
        padding: .5rem 1.5rem;
    }

    .alert-danger {
        border: 0;
        border-left: 4px solid #721c24;
        p {
            margin: 0;
            font-size: 14px;
            color: #721c24;
        }
    }
}
